import { useState, useEffect } from "react";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Pricetag } from "./components/pricetag/pricetag"

import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

import { Footer } from "./components/footer";

import Crous from "./components/crous";
import Caurousel from "./components/Caurousel";


//import MediaCard from "./components/future/mediacard"

import { green } from '@mui/material/colors';
import Crous2 from "./components/Crous2";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Chooseus from "./components/chooseus";

import Footerdetail from "./components/Footerdetail";

import Certifications from "./components/Certifications";
import { Products } from "./components/products/Products"



export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

{/*
const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};
 const fabGreenStyle = {
    color: 'common.white',
    bgcolor: green[500],
    '&:hover': {
      bgcolor: green[600],
    },
  };

*/}


const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);


  return (
    <div>
      <Header data={landingPageData.Header} />
      <Contact data={landingPageData.Contact} />


      {/*<Crous />
        <Crous2 /> 
        <Caurousel />
        
        for whatspp web use link href="https://wa.me/9060591201"
      */}

      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Pricetag data={landingPageData.Pricetag} />
      
      <Chooseus />

      <Services data={landingPageData.Services} />

      <Testimonials data={landingPageData.Testimonials} />
      
      {/*<MediaCard /> <Pricetag data={landingPageData.Pricetag} />
        <Certifications />
      <Products data={landingPageData.Products} />
        <Footerdetail data={landingPageData.Contact} />
        <Footer data={landingPageData.Footer} />
    */}
      
    </div>
  );

}


export default Home;
